@font-face {
  font-family: "Prompt-Regular";
  src: url("../assets/fonts/Prompt/Prompt-Regular.ttf");
}

@font-face {
  font-family: "Prompt-Medium";
  src: url("../assets/fonts/Prompt/Prompt-Medium.ttf");
}

@font-face {
  font-family: "Prompt-SemiBold";
  src: url("../assets/fonts/Prompt/Prompt-SemiBold.ttf");
}

html body {
  font-family: "Prompt-Regular";
  margin: 0px;
  color: #222221;
}

.ant-layout-content {
  background-color: transparent;
}

.ant-layout.layout-custom {
  min-height: 100vh;
  border-left: 15px solid #5EA09F;
  width: 100%;
  background: url("../assets/image/logo/background-main.svg");
  background-repeat: no-repeat, repeat;
  background-attachment: fixed;
  background-position: right bottom;
  background-color: #fff;
}

.ant-layout-header.custom-header{
  background-color: #fff;
  text-align: end;
}

.ant-spin-dot-item {
  background-color: #5EA09F !important;
}