.wrap-compoent-approve-teacher {
  margin: 5px 15px;
  .wrap-border-green-approve-teacher {
    display: flex;
    width: 100%;
    height: 2px;
    background-color: #5ea09f;
    border: none;
    border-radius: 8px;
  }
  .ant-btn.btn-view-doc {
    background-color: #006664;
    height: 44px;
    border: 2px solid #006664;
    border-radius: 8px;
    color: #ffffff;
    width: 144px;
  }
  .txt-label {
    color: #006664;
    font-weight: bold;
    font-size: 16px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #006664 !important ;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #006664;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #006664;
  }

  .wrap-canvas {
    position: relative;
    background-color: #ececec;
    border-radius: 8px;
    max-width: 368px;
    padding: 12px 16px;
    .wrap-image-signature {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    canvas {
      background-color: #ffffff;
      border-radius: 8px;
    }
    button {
      position: absolute;
      right: 16px;
      top: 12px;
      background-color: transparent;
      color: #006664;
      border: none;
      box-shadow: none;
      font-size: 14px;
    }
  }
  .wrap-please-approv{
    .ant-input{
      height: 40px;
      border-radius: 8px;
    }
  }
  .btn-approve-teacher {
    .ant-btn {
      width: 130px;
      height: 44px;
      margin: 0px 5px;
      &:nth-child(1) {
        background: #006664 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        color: #ffffff;
      }
      &:nth-child(2) {
        border: 2px solid #006664;
        border-radius: 8px;
        opacity: 1;
        color: #006664;
      }
    }
  }
}
