.wrap-profile-page {
  padding: 20px 40px;
  .wrap-profile-page-header {
    display: flex;
    .ant-btn {
      border: none;
      margin-right: 10px;
      span {
        font-size: 20px;
        color: #006664;
      }
    }
    p {
      font-size: 18px;
      font-weight: bold;
      color: #006664;
      margin-bottom: 0px;
    }
    .wrap-border-bottom-yellow {
      margin-top: 5px;
      max-width: 150px;
      width: 100%;
      height: 4px;
      background-color: #ffd800;
    }
  }

  .wrap-profile-page-content {
    margin-top: 15px;
    .wrap-profile-page-content-section-header {
      @media (max-width: 450px) {
        .ant-row {
          justify-content: center;
          text-align: center;
        }
      }
      .wrap-profile-page-header-img {
        display: flex;
        align-items: center;
        p {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0px;
          &:nth-child(1) {
            margin-bottom: 10px;
          }
        }
      }

      .wrap-profile-page-content-section-header-btn-group {
        @media (max-width: 394px) {
          .ant-btn {
            margin: 0px !important;
            &:nth-child(2) {
              margin-top: 10px !important;
            }
          }
        }
        @media (max-width: 450px) {
          .ant-row-end {
            justify-content: center;
          }
        }
        .ant-btn {
          width: 150px;
          height: 44px;
          margin: 10px;
          border-radius: 8px;
          font-weight: bold;
          font-size: 16px;
          &:nth-child(2) {
            background-color: #fff;
            border: 2px solid #006664;
            color: #006664;
          }
          &:nth-child(1) {
            border: none;
            color: #fff;
            background-color: #006664;
          }
        }
      }
    }

    @media (max-width: 450px) {
      .wrap-content-profile-info {
        .ant-col-md-12 {
          width: 100%;
        }
        .ant-col-md-10 {
          width: 50%;
        }
        .ant-col-md-14 {
          width: 50%;
          p {
            word-wrap: break-word;
          }
        }
      }
    }

    .wrap-content-profile-info {
      width: 100%;
      @media (max-width: 450px) {
        .ant-row {
          margin-top: 5px;
          row-gap: 0px !important;
        }
      }
      .wrap-box-profile-page {
        margin-top: 15px;
        border: 1px solid #e3e3e3;
        padding: 20px;
        border-radius: 20px;
        background-color: #fff;
        p.wrap-txt-section {
          color: #006664;
          font-weight: 16px;
          font-weight: bold;
          margin-right: 6px;
        }
      }
    }
  }
  .top-txt-content {
    color: #006664;
    font-size: 20px !important;
    font-family: "Prompt-SemiBold";
  }
}
