div.wrap-edit-profile-page {
  .ant-form-item-label {
    padding: 0;
    label {
      font-size: 16px;
      font-weight: bold;
      color: #222221;
    }
  }

  // border-left: 10px solid #006664;
  .wrap-content-edit-profile-page {
    margin: 35px 100px;
    .wrap-header-edit-profile-page {
      display: flex;
      .ant-btn {
        border: none;
        margin-right: 10px;
        span {
          font-size: 20px;
          color: #006664;
        }
      }
      p {
        margin-bottom: 1.5px;
        font-size: 18px;
        font-weight: bold;
        color: #006664;
      }
      .wrap-main-yellow-page {
        margin-top: 5px;
        max-width: 188px;
        width: 100%;
        height: 4px;
        background-color: #ffd800;
      }
    }
    p.warp-label-header {
      font-size: 18px;
      display: inline;
      border-bottom: 2px solid #ffd800;
      font-weight: bold;
      color: #006664;
    }

    .wrap-section-upload-photo {
      margin: 30px 20px;
      .wrap-backgroud-image {
        background-color: #dcf4ed;
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .txt-upload-image {
        font-size: 16px;
        font-weight: bold;
        margin-left: 20px;
      }
    }

    .wrap-edit-profile-form {
      @media (max-width: 450px) {
        .ant-col {
          width: 100%;
        }
      }
      .ant-row {
        margin-bottom: 8px;
      }
      .warp-txt-area-form {
        .ant-input {
          height: auto;
        }
      }

      .ant-picker {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #c4c4c4;
      }

      .btn-browe-file {
        display: inline-block;
        background: #5ea09f 0% 0% no-repeat padding-box;
        color: white;
        padding: 0.5rem;
        border-radius: 0px 8px 8px 0px;
        height: 40px;
        font-family: sans-serif;
        cursor: pointer;
        text-align: center;
      }

      .ant-input {
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #c4c4c4;
        border-radius: 8px;
      }

      .ant-select {
        height: 40px;
        width: 100%;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #c4c4c4;
        border-radius: 8px;
        display: flex;
        justify-content: center;
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        margin-top: 4px;
      }

      .ant-input[disabled] {
        background: #ebebeb 0% 0% no-repeat padding-box;
        border: 1px solid #c4c4c4;
        border-radius: 8px;
      }
      .wrap-edit-profile-box {
        padding: 30px 40px;
        border: 1px solid #e3e3e3;
        border-radius: 20px;
        background-color: #fff;
      }
      .txt-label-edit-form {
        font-size: 16px;
        font-weight: bold;
        color: #222221;
      }

      .wrap-upload-document {
        .ant-btn {
          border: 2px solid #006664;
          border-radius: 8px;
          height: 40px;
          color: #006664;
          font-weight: bold;
          margin-left: 5px;
        }

        .ant-input {
          &.wrap-input-local-file-name {
            border-radius: 8px 0px 0px 8px;
          }
        }

        @media (max-width: 450px) {
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-btn {
            margin-top: 10px;
          }
        }
      }
      .wrap-list-file-upload {
        @media (max-width: 450px) {
          width: 100%;
        }
        .row-file-name-list {
          border-bottom: 1px #dddddd dotted;
        }
        .txt-file-name {
          color: #006664;
          font-weight: bold;
          font-size: 14px;
        }
        .txt-delete {
          color: #f80303;
          font-weight: bold;
          font-size: 14px;
        }
      }

      .wrap-button-section-form {
        margin-top: 15px;
        @media (max-width: 450px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-btn {
          height: 44px;
          font-weight: bold;
          font-size: 16px;
          border-radius: 8px;
          &:nth-child(1) {
            background: #006664 0% 0% no-repeat padding-box;
            border: none;
            color: #fff;
          }
          &:nth-child(2) {
            border: 2px solid #006664;
            background-color: #ffffff;
            color: #006664;
            margin-left: 15px;
          }
        }
      }
    }
  }

  .top-txt-content {
    color: #006664;
    font-size: 20px !important;
    font-family: "Prompt-SemiBold";
  }
  @media (max-width: 450px) {
    .wrap-content-edit-profile-page {
      margin: 35px 10px;
    }
  }
  @media (max-width: 820px) {
    .wrap-content-edit-profile-page {
      margin: 35px 15px;
    }
  }
}
