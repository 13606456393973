.warp-modal-view-status-document-student{
    .ant-modal-content{
        border-radius: 10px;
        .wrap-txt-section{
            color: #006664;
            font-weight: bold;
            font-size: 16px;
        }
        .wrap-txt-detail{
            color: #222221;
            font-size: 16px;
        }
        .ant-btn.modal-view-status-btn-preview-document{
            height: 40px;
            border: 2px solid #006664;
            color: #006664;
            border-radius: 8px;
        }
        .wrap-txt-document{
            margin-bottom: 3px;
            text-decoration: underline;
            cursor: pointer;
            font-size: 14px;
        }

        .form-file-modal-student{
            text-decoration: underline;
            font-weight: bold;
            color: #006664;

        }
    }
    .ant-modal-footer{
        border-top:none;
    }
}