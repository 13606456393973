.warp-dashborad-page {
  margin: 10px 30px;
  .wrap-dashboard-header-section {
    display: flex;
    .ant-btn {
      border: none;
      margin-right: 10px;
      span {
        font-size: 20px;
        color: #006664;
      }
    }
    p {
      font-size: 18px;
      font-weight: bold;
      color: #006664;
      margin-bottom: 0px;
    }
    .wrap-border-bottom-yellow {
      margin-top: 5px;
      max-width: 150px;
      width: 100%;
      height: 4px;
      background-color: #ffd800;
    }
  }
  .wrap-dashboard-search-section {
    margin-top: 15px;
    .wrap-dashborad-label-txt {
      color: #222221;
      font-size: 16px;
      font-weight: bold;
    }
    .ant-select {
      width: 95%;
      height: 40px;
      &:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
        border: 1px solid #c4c4c4;
        border-radius: 8px;
      }
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      margin-top: 4px;
    }
    .ant-picker {
      width: 100%;
      height: 40px;
      border: 1px solid #c4c4c4;
      border-radius: 8px;
    }
    .ant-btn {
      &.wrap-btn-search-dashboard {
        margin-top: 40px;
        height: 40px;
        width: 140px;
        background: #006664 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        color: #fff;
        margin-left: 10px;
      }
      @media (max-width: 450px) {
        &.wrap-btn-search-dashboard {
          margin-top: 10px;
          margin-left: 0px;
        }
      }
    }
  }
  .wrap-hr-search-section {
    width: 100%;
    height: 1px;
    background-color: #5ea09f;
    margin: 15px 0px;
  }
  .wrap-chart-dashboard-section {
    display: flex;

    @media (max-width: 450px) {
      display: inline-block;
    }

    .wrap-chart-dashboard {
      width: 320px;
    }
    .wrap-chart-dashboard-detail {
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .wrap-list-chart-detail {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        .chart-box-color {
          width: 12px;
          height: 12px;
        }
        span {
          margin-left: 10px;
        }
      }
    }
  }

  .wrap-dashbord-chart-detail {
    margin: 30px 0px;
    .header-chart-detail {
      font-size: 16px;
      background: #f3f3f3 0% 0% no-repeat padding-box;
      color: #222221;
      font-weight: bold;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0px 20px;
    }
    .content-chart-detail {
      padding: 0px 20px;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 3px dotted #f3f3f3;
    }
    .footer-chart-detail {
      font-size: 16px;
      height: 40px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      background: #dcf4ed 0% 0% no-repeat padding-box;
      font-weight: bold;
    }
  }
}

@media (max-width: 768px) {
  table.table-report {
    width: 100%;
  }
}

.table-report {
  width: 50%;
  font-size: 16px;
  thead {
    th {
      background-color: #f3f3f3;
      padding: 6px;
      &:first-child {
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      td {
        &:first-child {
          text-align: left;
        }
        padding: 6px;
        text-align: center;
      }
    }
  }
  tfoot {
    td {
      &:first-child {
        text-align: left;
      }
      background-color: #dcf4ed;
      padding: 6px;
      text-align: center;
    }
  }
}
