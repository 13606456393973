.wrap-header-app-component{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .wrap-bg-img-icon-header{
        background-color: #5EA09F;
        padding: 8px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 20px;
            height: 20px;
        }
        
    }
}