.wrap-list-document-student-page {
    .wrap-list-document-student-page {
        padding: 20px 80px;
        @media (max-width: 450px) {
            padding: 10px 20px;
        }
        .wrap-header-txt-list-document-student {
            display: flex;
            .ant-btn{
                border: none;
                margin-right: 10px;
                span{
                    font-size: 20px;
                    color:#006664;
                }
            }
            span {
                font-size: 18px;
                font-weight: bold;
                color: #006664;
            }
            .wrap-document-student-yellow-buttom-page {
                margin-top: 5px;
                max-width: 250px;
                width: 100%;
                height: 4px;
                background-color: #ffd800;
          
            }
        }
        .wrap-search-section-document-student {
            margin-top: 30px;
            .ant-btn.btn-search-form-doc-student{
                margin-top: -1px;
            }
            .ant-btn.btn-go-report-page{
                margin-top: -1px;
            }
            .ant-input {
                height: 40px;
                border: 1px solid #c4c4c4;
                border-radius: 8px;
            }
            .ant-select {
                width: 100%;
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                height: 40px;
                border: 1px solid #c4c4c4;
                border-radius: 8px;
                width: 100%;
            }
            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                margin-top: 4px;
            }

            .ant-btn {
                margin-top: 25px;
                width: 160px;
                height: 40px;
                background: #006664 0% 0% no-repeat padding-box;
                border-radius: 8px;
                color: #FFFFFF;
                font-weight: normal;
                font-size: 16px;
            }
            .txt-heder-search-document-student{
                font-size: 16px;
                font-weight: normal;
            }
            @media (max-width: 450px) {
                .ant-col{
                    width: 100%;
                }
                .ant-row-end{
                    justify-content: start;
                }
            }
        }


        .wrap-content-table-document-student{
            margin-top: 20px;
            width: 100%;
            @media (max-width:450px) {
                //    min-height: 100vh; 
                margin-bottom: 10px;
                overflow: auto;
            }
            .ant-table-thead>tr>th{
                background: #F3F3F3 0% 0% no-repeat padding-box;
                font-size: 14px;
                color: #222221;
                font-weight: bold;
            }
            .ant-table-pagination-right{
                justify-content: flex-start ;
                margin-top: 20px;
            }
            .ant-pagination-item-active{
             
                border: none;
                border: 5px;
                background-color:  #F3F3F3;
                a {
                    color: #222221;
                }
            }
            .wrap-group-button-list-document-student{
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    margin: 0px 10px;
                    cursor: pointer;
                    &.btn-disble{
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }

              
            }
        }
    }
}



    .top-txt-content {
        color: #006664;
        font-size: 20px;
        font-family: "Prompt-SemiBold";
    }
    .form-success-message {
        color: #17B81C;
    
        a:link {
            color: #17B81C;
        }
    
        a:visited {
            color: #17B81C;
        }
    
        a:hover {
    
            color: #17B81C;
        }
    
        a:active {
            color: #17B81C;
    
        }
    }