.content-http-status-page{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    p{
        margin-bottom: 0px;
    }
    p.txt-status{
        color: #5EA09F;
        font-weight: bold;
        font-size: 100px;
    }
    p.status-description{
        color: #5EA09F;
        font-weight: bold;
        font-size: 50px;
    }
    .ant-btn.btn-back-login{
        width: 170px;
        height: 45px;
        border-radius: 8px;
        border: none;
        background-color: #5EA09F;
        color: aliceblue;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
    }
}