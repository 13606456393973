@media only screen and (max-width: 500px) {
  div.wrap-button-action {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      margin-right: 0;
    }
    button {
      width: 110px;
    }
  }
}
