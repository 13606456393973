@import "./responsive.scss";

.wrap-form {
  overflow: hidden;
  .header-text-style {
    color: #222221;
    font-size: 18px;
    font-family: "Prompt-Medium";
    position: relative;
    padding-left: 14px;
    &:before {
      content: "";
      height: 9px;
      width: 9px;
      background-color: #ffd800;
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
}

.wrap-form-1 {
  .form-ele-sign {
    .wrap-canvas {
      position: relative;
      background-color: #ececec;
      border-radius: 8px;
      max-width: 368px;
      padding: 12px 16px;
      .wrap-image-signature {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      canvas {
        background-color: #ffffff;
        border-radius: 8px;
      }
      button {
        position: absolute;
        right: 16px;
        top: 12px;
        background-color: transparent;
        color: #006664;
        border: none;
        box-shadow: none;
        font-size: 14px;
      }
    }
  }
  .ant-picker.ant-picker-range {
    height: 40px !important;
    border-radius: 8px !important;
  }
  input {
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
  }
  .ant-radio-checked {
    &:after {
      border: 1px solid #006664;
    }
    .ant-radio-inner {
      border-color: #006664 !important;
      &::after {
        background-color: #006664 !important;
      }
    }
  }
  textarea {
    border-radius: 8px;
  }
  .ant-input-number {
    border-radius: 8px;
    height: 40px;
  }
  .ant-select-selection-placeholder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ant-select-selector,
  .ant-select {
    height: 40px !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
  }
  .remove-element {
    vertical-align: top;
    margin-top: 1px;
    display: inline-block;
    color: #ff0000;
    span svg {
      width: 22px;
      height: 22px;
    }
  }
  .add-element {
    border: none;
    box-shadow: none;
    width: 200px;
    text-align: left;
    padding: 0;
    color: #006664;
    font-size: 16px;
    &:hover,
    &:focus {
      color: #006664;
    }
  }
}

.wrap-form-7-flex {
  display: flex;
}
p.form-7-text-explain {
  font-family: "Prompt-Medium";
  font-size: 16px;
  color: #222221;
}

.wrap-from-7-upload-section {
  border: 1px solid #5ea09f;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 8px;
  &.upload-form-1 {
    border: none;
    padding: 0;
    p {
      margin-bottom: 0;
      font-size: 16px;
      color: #222121b1;
      font-family: "Prompt-Medium";
    }
  }
  .custom-required {
    color: #ff0000b1;
  }
  .upload-section {
    margin-bottom: 14px;
  }
  .file-name-section {
    line-height: 40px;
    padding-left: 16px;
  }
  .ant-upload-list-item {
    display: none;
  }
  .wrap-upload-component {
    display: flex;
    .file-name-section {
      height: 40px;
      border: 1px solid #d9d9d9;
      border-radius: 8px 0 0 8px;
      width: calc(100% - 40px);
    }
    .upload-btn {
      height: 40px;
    }
    .ant-btn-icon-only {
      height: 40px;
      width: 40px;
      border-radius: 0 8px 8px 0;
      background-color: #5ea09f;
      color: #ffffff;
      border: 1px solid #5ea09f;
      &:hover,
      &:active,
      &:focus {
        background-color: #5ea09f;
        border: 1px solid #5ea09f;
        color: #ffffff;
      }
    }
  }
}
.radio-group-form-8 {
  label {
    border: 1px solid #5ea09f;
    border-radius: 8px;
    padding: 24px;
    margin-right: 0;
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .second-text,
  ul {
    font-family: Prompt-Regular;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
  }
}

.form-8-upload-section {
  margin-top: 24px;
}

.wrap-dynamic-responsive {
  overflow: scroll;
  &.form-ku-1,
  &.form-ku-3 {
    .ant-space-item {
      label,
      input,
      span.ant-select-selection-item {
        font-size: 14px;
      }
    }
  }
}

.form2-input-after,
.addon-after-border-radios {
  span.ant-input-group-addon {
    border-radius: 0 8px 8px 0;
  }
}

.wrap-form-topic-radio {
  .ant-form-item-control {
    .ant-radio-group {
      display: flex;
      label {
        width: auto;
      }
    }
  }
}

.custom-upload-field {
  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
  }
  .ant-btn {
    width: 100%;
    position: relative;
    height: 40px;
    border-radius: 8px;
    :hover,
    :focus,
    :active,
    :visited {
      color: #000000;
    }
    .anticon-cloud-upload {
      background-color: #5ea09f;
      color: #ffffff;
      position: absolute;
      font-family: sans-serif;
      border-radius: 0 8px 8px 0;
      right: 0;
      top: 0;
      height: 39px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
