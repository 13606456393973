.wrap-login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    background: url("../../assets/image/logo/background-main.svg");
    background-repeat: no-repeat, repeat;
    background-attachment: fixed;
    background-position: right bottom;
    background-color: #fff;
    .wrap-login-content {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .wrap-label-login-page {
            margin-top: 10px;
            text-align: center;
            font-weight: bold;
            font-size: 24px;
            color: #006664;
        }
        .login-box-yellow {
            width: 70px;
            height: 4px;
            background-color: #ffd800;
        }
        .wrap-login-form {
            margin: 25px 0px;
            p {
                font-size: 16px;
                margin-bottom: 3px;
            }
            .ant-input {
                height: 40px;
                width: 335px;
                border-radius: 8px;
            }
            .ant-btn.btn-login {
                width: 230px;
                height: 44px;
                background-color: #006664;
                border: none;
                color: #fff;
                border-radius: 8px;
            }
        }
        .btn-submit-login-form {
            margin-top: 15px;
            &.ant-btn {
                width: 100%;
                height: 44px;
                border-radius: 8px;
                background-color: #006664;
                color: #fff;
            }
        }
    }
}
