.site-layout-background {
  padding: 0 4%;
  .top-txt-content {
    color: #006664;
    font-size: 20px;
    font-family: "Prompt-SemiBold";
  }
}

.navigate-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right {
    button {
      background-color: #fff;
      border: 2px solid #006664;
      color: #006664;
      width: 150px;
      height: 44px;
      border-radius: 8px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  h1 {
    margin-bottom: 0;
    border-bottom: 2px solid #ffd800;
    line-height: 40px;
  }

  button {
    border: none;
    color: #006664;
    box-shadow: none;
  }
}

form.wrap-form-request {
  hr {
    border-top: 1px solid #5ea09f;
  }
  margin-top: 32px;
  label {
    font-family: "Prompt-Medium";
    font-size: 16px;
    color: #222221;
    width: 100%;
  }
  .ant-select-arrow {
    color: #006664;
  }
}

.select-form-type {
  .ant-select-selection-placeholder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ant-select-selector,
  .ant-select {
    height: 40px !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
  }
}

.wrap-button-action {
  display: flex;
  .ant-form-item {
    margin-right: 15px;
  }
  button {
    width: 130px;
    height: 50px;
    border-radius: 8px;
    &.save-draft {
      background-color: #ffffff;
      border: 2px solid #006664;
      color: #006664;
    }
    &.sent-request {
      background-color: #006664;
      border: 2px solid #006664;
      color: #ffffff;
      width: 140px;
    }
    &:disabled {
      background-color: #5ea09f;
      color: #ffffff;
      border: 1px solid #5ea09f;
      &:hover {
        background-color: #5ea09f;
        color: #ffffff;
        border: 1px solid #5ea09f;
      }
    }
  }
}
