.warp-modal-view-status-document-student{
    .ant-modal-content{
        border-radius: 10px;
        .wrap-txt-section{
            color: #006664;
            font-weight: bold;
            font-size: 16px;
        }
        .wrap-txt-detail{
            color: #222221;
            font-size: 16px;
            &.pending{
                color:#E38417
            }
            &.success{
                color: #56C85A;
            }
        }
        .ant-btn.modal-view-status-btn-preview-document{
            height: 40px;
            border: 2px solid #006664;
            color: #006664;
            border-radius: 8px;
        }
        .wrap-txt-document{
            margin-bottom: 3px;
            text-decoration: underline;
            cursor: pointer;
            font-size: 14px;
        }
    }
    .ant-modal-footer{
        border-top:none;
    }
    .top-txt-content {
        color: #006664;
        font-size: 20px;
        font-family: "Prompt-SemiBold";
    }

}