.wrap-component-main-page {
  // border-left: 10px solid #5EA09F;
  // min-height: 100vh;
  // width: 100%;
  // background: url('../../assets/image/logo/background-main.svg');
  // background-repeat: no-repeat, repeat;
  // background-attachment: fixed;
  // background-position: right bottom ;
  .wrap-content-main-page {
    padding: 15px 40px;
    @media (max-width: 450px) {
      padding: 15px 30px;
    }

    .wrap-header-main-page {
      .header-text {
        margin-top: 18px;
      }
      p {
        margin-bottom: 1.5px;
        font-size: 30px;
        font-weight: bold;
        color: #006664;
        border-bottom: 4px solid #ffd800;
        display: inline-block;
      }

      .wrap-main-yellow-page-student {
        margin-top: 5px;
        max-width: 335px;
        width: 100%;
        height: 4px;
        background-color: #ffd800;
      }

      .wrap-main-yellow-page {
        margin-top: 5px;
        max-width: 375px;
        width: 100%;
        height: 4px;
        background-color: #ffd800;
      }
    }
    @media (max-width: 450px) {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    .wrap-list-main-menu {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      height: calc(100vh - 400px);
      align-items: center;

      @media (max-width: 768px) {
        display: inline-block;
        width: 100%;
        .wrap-box-main-menu {
          margin: 10px 0px !important;
        }
      }

      .wrap-box-main-menu {
        cursor: pointer;
        margin: 0px 10px;
        max-height: 250px;
        height: 100%;
        width: 20%;
        min-width: 180px;
        border: 2px solid #5ea09f;
        padding: 15px;
        position: relative;
        border-radius: 20px;
        p {
          font-size: 24px;
          color: #006664;
        }
        img.icon-main-absolute {
          position: absolute;
          bottom: 15px;
          right: 15px;
        }

        @media (max-width: 450px) {
          display: flex;
          width: 100%;
          height: 80px;
          align-items: center;
          p {
            width: 70%;
            margin-bottom: 0px;
          }
          .wrap-img-list-main-menu {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img.icon-main-absolute {
              position: relative;
              width: 35px;
              height: 35px;
              bottom: 0;
              right: 0;
            }
          }
        }

        @media (max-width: 820px) {
          min-width: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrap-component-main-page
    .wrap-content-main-page
    .wrap-list-main-menu
    .wrap-box-main-menu {
    width: 100%;
  }

  .wrap-component-main-page
    .wrap-content-main-page
    .wrap-list-main-menu
    .wrap-box-main-menu {
    height: 80px;

    .icon-size {
      width: 40px;
    }
  }
}
.warning-msg {
  padding: 15px 40px;
  font-size: 24px;
  color: red;
}

.icon-size {
  width: 55px;
}

@media (max-height: 414px) {
  .wrap-component-main-page .wrap-content-main-page .wrap-list-main-menu {
    height: calc(100vh - 280px);
  }
}
