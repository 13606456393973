.wrap-list-document-teacher-page {
    .wrap-list-document-teacher-page {
        padding: 40px 80px;
        @media (max-width: 450px) {
            padding: 10px 15px;
        }
        .wrap-header-txt-list-document-teacher {
            display: flex;
            .ant-btn{
                border: none;
            }
            span {
                font-size: 18px;
                font-weight: bold;
                color: #006664;
            }
            .wrap-document-teacher-yellow-buttom-page {
                margin-top: 5px;
                max-width: 110px;
                width: 100%;
                height: 4px;
                background-color: #ffd800;
            }
        }
        .wrap-search-section-document-teacher {
            margin-top: 30px;
            .ant-btn.btn-search-form-doc-teacher {
                margin-top: -1px;
            }
            .ant-btn.btn-go-report-page {
                margin-top: -1px;
            }
            .ant-input {
                height: 40px;
                border: 1px solid #c4c4c4;
                border-radius: 8px;
            }
            .ant-select {
                width: 100%;
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                height: 40px;
                border: 1px solid #c4c4c4;
                border-radius: 8px;
                width: 100%;
            }
            .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                margin-top: 4px;
            }

            .ant-btn {
                margin-top: 25px;
                width: 160px;
                height: 40px;
                background: #006664 0% 0% no-repeat padding-box;
                border-radius: 8px;
                color: #ffffff;
                font-weight: normal;
                font-size: 16px;
            }
            .txt-heder-search-document-teacher {
                font-size: 16px;
                font-weight: normal;
            }

            @media (max-width: 450px) {
                .ant-col{
                    width: 100%;
                }
            }
        }

        .wrap-content-table-document-teacher {
            margin-top: 20px;
            width: 100%;
            @media (max-width: 450px) {
                // min-height: 100vh;
                margin-bottom: 20px;
                overflow: auto;
            }
            .ant-table-thead > tr > th {
                background: #f3f3f3 0% 0% no-repeat padding-box;
                font-size: 14px;
                color: #222221;
                font-weight: bold;
            }
            .ant-table-pagination-right {
                justify-content: flex-start;
                margin-top: 20px;
            }
            .ant-pagination-item-active {
                border: none;
                border: 5px;
                background-color: #f3f3f3;
                a {
                    color: #222221;
                }
            }
            .wrap-group-button-list-document-teacher {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    margin: 0px 5px;
                }
                .ant-btn {
                    height: 34px;
                    border: 1px solid #006664;
                    color: #006664;
                    border-radius: 8px;
                    margin: 0px 3px;
                }
                .ant-btn:disabled{
                    opacity: 0.5;
                }
            }

            .txt-list-teacher-status{
                font-weight: bold;
                &.PENDING{
                    color: #E38417;
                }
                &.SUCCESS{
                    color: #17B81C;
                }
                &.APPROVED{
                    color: #17B81C;
                }
                &.REJECTED{
                    color: #c30202;
                }
            }
        }
    }
}
